import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {bottomStyles, redBoxesStyles, upperStyles} from "../../stylesheets/solutionStyles";
import s1 from "../../assets/imgs/sl1.svg";
import s2 from "../../assets/imgs/sl2.svg";
import s3 from "../../assets/imgs/sl3.svg";

const Solutions = () => {
    const data = [
        {
            heading: 'Integrated Security System',
            icon: <img style={{height: '80%', width: '80%'}} src={s1} alt={'sol1'}/>,
            text: 'An integrated security system solution combines various security components like video surveillance, ' +
                'access control, alarm systems, and monitoring into a unified, cohesive system. This solution offers ' +
                'comprehensive coverage, seamless integration, and centralized control for enhanced security management ' +
                'across diverse environments, such as residential complexes, commercial spaces, or large-scale facilities.',
        },


        {
            heading: 'Risk Assessment and Customized Security Plan',
            icon: <img style={{height: '80%', width: '80%'}} src={s2} alt={'sol2'}/>,
            text: 'Conducting a thorough risk assessment allows for the identification of potential vulnerabilities and ' +
                'security gaps within an organization or property. Based on the assessment, a customized security plan ' +
                'is developed, tailoring security measures to address specific risks and concerns. This solution includes ' +
                'strategic recommendations for deploying manpower, implementing security technologies, establishing ' +
                'access controls, and emergency response protocols.',
        },

        {
            heading: '24/7 Remote Monitoring and Response Services',
            icon: <img style={{height: '80%', width: '80%'}} src={s3} alt={'sol3'}/>,
            text: 'Implementing round-the-clock remote monitoring and response services utilizes advanced technology to ' +
                'monitor security systems and premises remotely. Trained professionals oversee live surveillance feeds, ' +
                'analyze threats, and respond promptly to security incidents or alarms. This solution offers continuous ' +
                'vigilance, rapid response capabilities, and the ability to intervene in real-time, ensuring immediate ' +
                'action in the event of suspicious activities, breaches, or emergencies, providing peace of mind to ' +
                'clients about their property safety.',
        },
    ]

    return(<Box id="solutions" height={"100%"}>
        <Stack height="100%" display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box sx={upperStyles.mainBoxStyles}>
                <Box sx={upperStyles.redBorderBox}>
                    <Box sx={upperStyles.innerWhiteBox}/>
                </Box>
                <Box sx={upperStyles.blackBannerBox}>
                    <Typography sx={upperStyles.bannerText} variant={'h4'}>Solutions</Typography>
                </Box>
            </Box>

            <Stack direction={'row'} sx={redBoxesStyles.mainGridContainer}>
                <Box sx={redBoxesStyles.mainUpperMiddleBox}>
                    <Box sx={redBoxesStyles.blackBackgroundBox}/>
                </Box>
                {data.map((Item) =>(<Box height="100%" width="30%">
                    <Box sx={redBoxesStyles.redBoxesMainBox}>
                        {Item.icon}
                    </Box>

                    <Typography variant={'h4'} my={'0.5rem'}>{Item.heading}</Typography>
                    <Typography variant={'body2'} sx={{textAlign: 'start', textOverflow: 'ellipsis'}}>{Item.text}</Typography>
                </Box>))}
            </Stack>

            <Box sx={bottomStyles.mainBottomBox}>
                <Box sx={bottomStyles.blackBoxDesign}></Box>
            </Box>
        </Stack>
    </Box>)
}

export default Solutions;