import React, {useState} from 'react';
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List, ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import {appbarStyle, drawerStyles, logoStyle, nameStyle, toolbarStyle} from "../../stylesheets/navbarStyles";
import logo from "../../assets/imgs/so_logo.jpeg";
import {Home, MenuRounded, SupportAgent, EmojiObjects, Group, ModeComment, Work} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const SMNavbar = () => {
    const [open, setOpen] = useState(false);
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();

    const data = [
        {
            icon: <Home sx={drawerStyles.tabIconStyle}/>,
            title: "Home",
            data: "home",
        },

        {
            icon: <ModeComment sx={drawerStyles.tabIconStyle}/>,
            title: "About",
            data: "about",
        },

        {
            icon: <SupportAgent sx={drawerStyles.tabIconStyle}/>,
            title: "Services",
            data: "services",
        },

        {
            icon: <EmojiObjects sx={drawerStyles.tabIconStyle}/>,
            title: "Solutions",
            data: "solutions",
        },

        {
            icon: <Group sx={drawerStyles.tabIconStyle}/>,
            title: "Team",
            data: "team",
        },

        {
            icon: <Work sx={drawerStyles.tabIconStyle} />,
            title: "Career",
            data: "career",
        }
    ]

    const handleButtonClick = (id, index) => {
        setOpen(false);
        scrollToSection(id);
    };

    const handleNavigation = () => {
        navigate("/careers");
    }

    return(<Box>
        <AppBar position="static" sx={appbarStyle}>
            <Toolbar sx={toolbarStyle}>
                <img style={logoStyle} src={logo} alt={'logo'}/>
                <Typography variant="h6" sx={nameStyle}>Syed Saqlain Security Services LLC</Typography>
                <Box>
                    <Drawer PaperProps={{sx: drawerStyles.drawerSX}} anchor={'top'} open={open} onClose={() => setOpen(false)}>
                        <List>
                            {data.map((Item, Index) =>(<ListItemButton key={Index} sx={{width: '100%'}} onClick={() => (Index !== 5 ? handleButtonClick(Item.data, Index) : handleNavigation())}>
                                <ListItemIcon sx={{width: '100%'}}>
                                    {Item.icon}
                                    <ListItemText sx={drawerStyles.listStyle}>{Item.title}</ListItemText>
                                </ListItemIcon>
                            </ListItemButton>))}
                        </List>
                    </Drawer>

                    <IconButton sx={{color: 'var(--grey-theme-color)'}} onClick={() => setOpen(!open)}>
                        <MenuRounded />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    </Box>);
}

export default SMNavbar;