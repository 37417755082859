import bg from '../assets/imgs/about_bg.jpeg';

export const leftGridStyles = {
    blackBackgroundBox: {
        clipPath: 'polygon(0 0, 60% 0, 100% 100%, 0% 100%)',
        height: '100%',
        width: '75%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    leftBoxImage: {
        position: 'relative',
        bottom: '87.5%',
        height: '75%',
        left: '5%',
        width: '70%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 2,
    },

    leftRedBorderBox: {
        position: 'relative',
        height: '12.5%',
        left: '-9%',
        width: '85%',
        transform: 'skewX(20deg)',
        border: '7px solid var(--red-theme-color)',
        bottom: '95%',
        zIndex: 1,
    }
};

export const rightGridStyles = {
    topMainDesignBox: {
        width: '100%',
        height: '20%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'center',
        overflow: 'hidden',
    },

    rightBorderBoxDesign: {
        position: 'relative',
        right: '-2%',
        height: '30%',
        width: '40%',
        transform: 'skewX(-20deg)',
        borderTop: '7px solid var(--red-theme-color)',
        borderLeft: '7px solid var(--red-theme-color)',
    },

    rightBannerBox: {
        position: 'relative',
        right: '-2%',
        height: '40%',
        width: '70%',
        backgroundColor: 'var(--grey-theme-color)',
        transform: 'skewX(-20deg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: '0.5rem',
    },

    bannerText: {
        fontSize: {md: '30px', lg: '35px', xl: '40px'},
        fontWeight: 'bold',
        color: '#FFF',
        transform: 'skewX(20deg)',
    },

    infoText: {
        fontSize: {md: '16px', lg: '18px', xl: '22px'},
        width: {md: '425px', lg: '575px', xl: '750px'},
        marginTop: {md: '15%'},
        marginRight: '1rem',
        marginLeft: 'auto',
    }
};

export const smallVisionStyles = {
    topBannerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: '0.5rem',
        backgroundColor: 'var(--grey-theme-color)',
        width: '100vw',
        borderBottom: '5px solid var(--red-accent-color)',
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    leftBox: {
        borderRight: '5px solid var(--red-accent-color)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightBox: {
        borderLeft: '5px solid var(--red-accent-color)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    infoText: {
        width: '90%',
        borderLeft: '5px solid var(--red-theme-color)',
        borderRight: '5px solid var(--red-theme-color)',
        marginX: 'auto',
        padding: '0.5rem',
        fontSize: {sm: '22px'},
    },

    leftBottomBox: {
        borderBottom: '5px solid var(--red-accent-color)',
        borderRight: '5px solid var(--red-accent-color)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightBottomBox: {
        borderBottom: '5px solid var(--red-accent-color)',
        borderLeft: '5px solid var(--red-accent-color)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },
};