import React from 'react';
import {AppBar, Box, Stack, Toolbar, Typography} from '@mui/material';
import {appbarStyle, buttonStyles, logoStyle, nameStyle, toolbarStyle} from "../../stylesheets/navbarStyles";
import logo from '../../assets/imgs/so_logo.jpeg';
import {useNavigate} from "react-router-dom";

const Navbar = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();

    const data = [
        {
            title: "Home",
            data: "home",
        },

        {
            title: "About",
            data: "about",
        },

        {
            title: "Services",
            data: "services",
        },

        {
            title: "Solutions",
            data: "solutions",
        },

        {
            title: "Team",
            data: "team",
        },

        {
            title: "Careers",
            data: "careers"
        }
    ];

    const handlePageNavigation = () => {
        navigate("/careers");
    }

    return (
        <div>
            <AppBar position="static" sx={appbarStyle}>
                <Toolbar sx={toolbarStyle}>
                    <Stack direction={'row'} alignItems={'center'} columnGap={'1rem'}>
                        <img style={logoStyle} src={logo} alt={'logo'}/>
                        <Typography variant="h6" sx={nameStyle}>
                            Syed Saqlain Security Services LLC
                        </Typography>
                    </Stack>
                    <Box>
                        {data.map((Item, index) =>(
                            <Box key={index} display={'inline'} sx={buttonStyles} onClick={() => (index !== 5 ? scrollToSection(Item.data) : handlePageNavigation())}>
                                {Item.title}
                            </Box>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;
