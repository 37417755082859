import headerBg from "../assets/imgs/security.jpg";

export const headerStyle = {
    mainImageContainer: {
        minHeight: '100vh',
        background: `url(${headerBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    floatingBackButton: {
        backgroundColor: 'var(--red-theme-color)',
        color: 'white',
        fontWeight: '700',
        position: 'fixed',
        right: '2.5rem',
        top: '5rem',
        '&:hover': {
            backgroundColor: 'white',
            color: 'var(--red-theme-color)'
        }
    }
}

export const hiringStyles = {
    imageStyles: {
        height: {xs: '100%', sm: '400px', md: '1', lg: '550px'},
        width: '100%'
    }
}