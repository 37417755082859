import {Box, Button, Container, Stack, Typography} from "@mui/material";
import {headerStyle, hiringStyles} from "../stylesheets/careersStyles";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {useNavigate} from "react-router-dom";
import support from "../assets/imgs/support.svg";
import growth from "../assets/imgs/growth.svg";
import dif from "../assets/imgs/difference.svg";
import hiring from "../assets/imgs/hiring.jpg";
import CareersFooter from "./sections/CareersFooter";
import security from "../assets/imgs/male_sec.png";
import Grid from '@mui/material/Unstable_Grid2';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {blue} from "@mui/material/colors";
import WhatsApp from "@mui/icons-material/WhatsApp";
import React from "react";

const CareersPage = () => {
    const navigate = useNavigate();

    const whatsappBtn = {
        color: 'white',
        height: '60px',
        width: '60px',
    };

    const whatsappBtnOuterBox = {
        position: 'fixed',
        zIndex: 50,
        height: '75px',
        width: '75px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00DE6B',
        bottom: {xs: 35, sm: 75},
        right: {xs: 35, sm: 75},
        borderRadius: '50%',
        border: '1px solid transparent',
        transition: '0.5s',
        transform: 'scale(0.8)',
        '&:hover': {
            boxShadow: '0px 0px 8px rgba(0,222,107,0.75)',
            border: '1px solid #bfbfbf',
            backgroundColor: '#009f50',
            transform: 'scale(1.1)',
        }
    }

    const data = [
        {
            icon: growth,
            title: 'Dynamic Career Growth Opportunities',
            info: 'Embark on a career path that offers continuous growth and development in the dynamic field of security ' +
                'solutions. At our company, you\'ll have the chance to expand your skill set, take on new challenges, ' +
                'and advance professionally as you contribute to safeguarding our clients\' assets and ensuring their ' +
                'peace of mind.'
        },

        {
            icon: support,
            title: 'Collaborative and Supportive Work Environment',
            info: 'Join a team that values collaboration, teamwork, and mutual support. We foster an inclusive work ' +
                'environment where every team member\'s contribution is recognized and valued. You\'ll have the ' +
                'opportunity to work alongside talented professionals who are passionate about security and dedicated ' +
                'to delivering exceptional service to our clients.'
        },

        {
            icon: dif,
            title: 'Make a Real Difference',
            info: 'At our security company, you\'ll have the opportunity to make a real difference in people\'s lives by ' +
                'helping to protect their homes, businesses, and communities. Your work will have a tangible impact on ' +
                'enhancing security measures and ensuring the safety and well-being of our clients. Join us in our ' +
                'mission to provide peace of mind and security for all.'
        }
    ];

    const hiringData = [
        {
            icon: security,
            title: 'We are Hiring Pakistanis'
        },

        {
            icon: security,
            title: 'We are Hiring Indians'
        },

        {
            icon: security,
            title: 'We are Hiring Nepalese'
        },

        {
            icon: security,
            title: 'We are Hiring Africans'
        },
        {
            icon: security,
            title: 'We are Hiring Indonesians'
        },
    ]

    const handleNavigate = () => {
        navigate("/");
    }

    return(
        <>
            <Box component={'a'} sx={whatsappBtnOuterBox} href={'https://wa.me/971526524544'}><WhatsApp sx={whatsappBtn}/></Box>
            <Box sx={headerStyle.mainImageContainer}>
                <Container maxWidth="xl" sx={{height: '100vh'}}>
                    <Button sx={headerStyle.floatingBackButton} startIcon={<ArrowLeftIcon />} onClick={()=> handleNavigate()}>Back</Button>
                    <Stack rowGap='1rem' width="fit-content" alignItems="start" justifyContent="center" height="inherit">
                        <Typography variant="h4" sx={{textDecoration: 'underline', textDecorationColor: 'var(--red-theme-color)', padding: '0.5rem 1rem', backgroundColor: 'rgba(255,255,255,0.75)'}}>Jobs at Syed Saqlain Security Services</Typography>
                        <Typography variant="h6" sx={{padding: '0.5rem 1rem', backgroundColor: 'rgba(255,255,255,0.75)'}}>Start your Employment Journey with Us</Typography>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{backgroundColor: '#eaeaea'}}>
                <Container maxWidth='xl'>
                    <Stack sx={{marginX: 'auto', padding: '3rem 1.5rem', rowGap: '1.5rem', textAlign: 'center'}}>
                        <Typography variant="h6" fontWeight={'900'}>Why Work with Us</Typography>
                        <Grid container maxWidth={{sm: '550px', md: '850px', lg: '1150px', xl: '1400px'}} marginX={'auto'}>
                            {data.map((item, key) =>(
                                <Grid key={key} item xs={12} md={6} lg={4} sx={{alignItems: 'stretch'}}>
                                    <Stack alignItems="center" justifyContent="center" padding={'0.5rem'} rowGap={'0.5rem'}>
                                        <Box component='img' src={item.icon} alt={"Icon"} sx={{height: '50px', width: '50px'}}/>
                                        <Typography variant="h6" fontWeight={'600'}>{item.title}</Typography>
                                        <Typography variant="body2">{item.info}</Typography>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </Box>

            <Box>
                <Container maxWidth='xl'>
                    <Grid container maxWidth={{sm: '550px', md: '850px', lg: '1150px', xl: '1400px'}} marginX={'auto'}>
                        <Grid item xs={12} md={6} alignItems="stretch" padding={'1rem 0.5rem'}>
                            <Stack justifyContent={'center'} alignItems={'start'} height={'100%'}>
                                <Typography variant="h6" fontWeight={'600'}>Join Us!</Typography>
                                <Typography variant="body2">
                                    Join our team at Syed Saqlain Security Services and be part of an esteemed organization
                                    dedicated to providing top-notch security solutions. We are currently seeking passionate
                                    individuals who are committed to excellence and eager to make a difference in the field
                                    of security services. As a member of our team, you'll have the opportunity to work with
                                    cutting-edge technologies, collaborate with industry experts, and contribute to our
                                    mission of ensuring safety and peace of mind for our clients. If you're ready to embark
                                    on a rewarding career path and be part of a dynamic team, we encourage you to apply and
                                    join us in our commitment to security excellence.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} alignItems="stretch" padding={'1rem 0.5rem'}>
                            <Box component="img" src={hiring} alt={"Hiring"} sx={hiringStyles.imageStyles} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{backgroundColor: '#eaeaea'}}>
                <Container maxWidth='xl'>
                    <Stack sx={{marginX: 'auto', padding: '3rem 1.5rem', rowGap: '1.5rem', textAlign: 'center'}}>
                        <Typography variant="h4" fontWeight={'900'} fontFamily={'Cantarell'}>We are Hiring!</Typography>
                        <Grid container maxWidth={{sm: '550px', md: '850px', lg: '1150px', xl: '1400px'}} marginX={'auto'} spacing={3}>
                            {hiringData.map((item) =>(
                                <Grid xs={12} md={6} lg={3} alignItems={'stretch'}>
                                    <Box boxShadow={'0 0 2px 2px rgba(255,0,0,0.5)'} height={'100%'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Stack alignItems={'center'} justifyContent={'center'} padding={2} rowGap={1} width={'100%'}>
                                            <Box sx={{borderRadius: '50%', backgroundColor: 'var(--red-accent-color)', height: '75px', width: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <Box component={'img'} src={security} alt={"Avatar"} sx={{height: '50px', width: '50px'}}/>
                                            </Box>
                                            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
                                                <NavigateBeforeIcon sx={{color: blue[800]}}/>
                                                <Typography variant={'h6'} fontWeight={'800'}>{item.title}</Typography>
                                                <NavigateNextIcon sx={{color: blue[800]}}/>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </Box>

            <CareersFooter />
        </>
    )
}

export default CareersPage;