import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {smallMissionStyles} from "../../stylesheets/missionStyles";

const SMMission = () => {
    return(<Box>
        <Box sx={smallMissionStyles.topBannerBox}>
            <Typography sx={smallMissionStyles.bannerTitle}>Our Mission</Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallMissionStyles.leftCircle}/>
            <Box sx={smallMissionStyles.rightCircle}/>
        </Stack>
        <Typography sx={smallMissionStyles.infoText}>
            Our mission is to provide exceptional manpower solutions tailored to our clients' needs,
            ensuring a safe and secure environment through our highly trained and professional security
            personnel. We aim to be the preferred partner for our clients by delivering reliable, skilled,
            and efficient manpower services. We strive to uphold the highest standards of integrity, training,
            and responsiveness, committed to safeguarding lives and assets while contributing to our clients'
            peace of mind.
        </Typography>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallMissionStyles.leftBottomCircle}/>
            <Box sx={smallMissionStyles.rightBottomCircle}/>
        </Stack>
    </Box>)
}

export default SMMission;