import bg from '../assets/imgs/header_bg.jpeg';
import logo from "../assets/imgs/so_logo.jpeg";

export const styles = {
    mainOuterContainer: {
        height: '100dvh',
    },
    horizontalBox: {
        backgroundColor: 'var(--red-theme-color)',
        height: {xs: '15px', md: '5px'},
        width: '100%',
    },
    mainStack: {
        paddingLeft: '2rem',
        height: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    headerBannerText: {
        fontSize: {xs: '32px', sm: '45px', md: '45px', lg: '60px', xl: '80px'},
        color: 'var(--red-theme-color)',
        fontWeight: 'bolder',
    },
    headerBannerBlackText: {
        fontSize: {xs: '32px', sm:'45px', md: '45px', lg: '60px', xl: '80px'},
        color: 'var(--grey-theme-color)',
        fontWeight: 'bolder',
    },
    iconSizing: {
        height: {xs: '40px', md: '50px', lg: '65px', xl: '75px'},
        width: {xs: '40px', md: '50px', lg: '65px', xl: '75px'},
        color: 'var(--red-theme-color)',
    },
    stackCentered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    logo: {
        height: '100px',
        width: '100px',
        borderRadius: '50%',
    },

    webText: {
        fontSize: {md: '20px', lg: '25px', xl: '30px'},
    },
};

export const rightBoxStyles = {
    rightCornerMainBox: {
        position: 'relative',
        width: '25%',
        height: '50%',
        right: '-75%',
        backgroundColor: 'transparent',
    },

    rightTopCornerStyle: {
        position: 'absolute',
        right: 0,
        width: '75%',
        height: '75%',
        clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 100% 100%)',
        backgroundColor: 'var(--grey-theme-color)',
        zIndex: 3,
    },

    rightTopCornerInnerStyle: {
        height: '100%',
        width: '100%',
        right: '0%',
        clipPath: 'polygon(0 0, 25% 0, 100% 75%, 100% 100%)',
        backgroundColor: 'var(--red-theme-color)',
        zIndex: 4,
    },

    rightTopCornerMostInnerStyle: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        right: '0%',
        clipPath: 'polygon(0 0, 25% 0, 100% 75%, 100% 100%)',
        backgroundColor: 'rgba(127,18,24,0.5)',
        zIndex: 5,
    },

    rightImageBox: {
        position: 'absolute',
        width: {xs: '100%', md: '50%'},
        right: '0%',
        clipPath: 'polygon(75% 0, 100% 50%, 100% 100%, 0 100%, 30% 0)',
        height: '100%',
        zIndex: 2,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },

    rightImageRibbon: {
        position: 'absolute',
        height: '100%',
        width: {xs: '33%',md: '16.5%'},
        right: {xs: '65%',md: "32.5%"},
        clipPath: 'polygon(90% 0, 100% 0, 10% 100%, 0% 100%);',
        backgroundColor: 'rgba(127,18,24,0.5)',
        zIndex: 7,
    },

    rightTopLeftBox: {
        clipPath: 'polygon(100% 0, 0 0, 70% 100%)',
        position: 'absolute',
        height: '30%',
        width: {xs: '30%', md: '15%'},
        zIndex: 6,
        backgroundColor: 'var(--red-theme-color)',
    },
};

export const smHeaderStyle = {
    mostOuterBox: {
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        paddingY: '1rem',
    },

    mainStack: {
        alignItems: 'center',
        rowGap: {xs: '7.5rem', sm: '10rem'},
    },

    innerStack: {
        height:'20%',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '0.5rem',
        marginX: '0.5rem'
    },

    logoBox: {
        minHeight: {xs: '50px', sm: '75px'},
        minWidth: {xs: '50px', sm: '75px'},
        borderRadius: '50%',
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },

    webTextSM: {
        fontSize: {xs: '15px', sm: '20px'},
        wordBreak: 'break-all',
    },
};