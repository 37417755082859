import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {bottomBoxStyle, upperBoxStyle} from "../../stylesheets/servicesCardStyles";
import s1 from "../../assets/imgs/s1.svg";
import s4 from "../../assets/imgs/s4.svg";
import s3 from "../../assets/imgs/s3.svg";
import s5 from "../../assets/imgs/s5.svg";
import s2 from "../../assets/imgs/s2.svg";

const servicesCard = () => {
    const data = [
        {
            icon: <img style={bottomBoxStyle.iconStyle} src={s1} alt={'service1'}></img>,
            heading: 'Manned Guarding Services',
            text: 'Our manned guarding services deploy trained security personnel equipped with expertise in surveillance, access control, and risk assessment. We ensure a visible security presence to deter threats and maintain a safe environment for residential, commercial, and institutional clients.',
        },

        {
            icon: <img style={bottomBoxStyle.iconStyle} src={s4} alt={'service2'}></img>,
            heading: 'Event Security Personnel',
            text: 'Specializing in event security, our skilled personnel are adept at managing crowd control, monitoring entry points, and handling emergency situations. We offer tailored event security solutions to ensure the safety and smooth execution of various events and gatherings.',
        },

        {
            icon: <img style={bottomBoxStyle.iconStyle} src={s3} alt={'service3'}></img>,
            heading: 'Mobile Patrol Security',
            text: 'Our mobile patrol security teams conduct regular patrols in designated areas, employing skilled personnel equipped with the ability to swiftly respond to security breaches. We provide comprehensive monitoring and a rapid response system to address emerging threats.',
        },

        {
            icon: <img style={bottomBoxStyle.iconStyle} src={s5} alt={'service4'}></img>,
            heading: 'Access Control Management',
            text: 'Our security experts manage access control systems, employing trained manpower to oversee entries, exits, and visitor management. With meticulous control measures, we ensure only authorized personnel gain access to restricted areas.',
        },

        {
            icon: <img style={bottomBoxStyle.iconStyle} src={s2} alt={'service5'}></img>,
            heading: 'Executive Protection Services',
            text: 'Offering specialized security details, our executive protection services cater to high-profile individuals, dignitaries, and corporate executives. Our trained security personnel provide discreet protection, ensuring the safety and confidentiality of our clients.',
        },

    ]

    return(<Box height="100%">
        <Stack height={"100%"}>
            <Box sx={upperBoxStyle.mainUpperBox}>
                <Box sx={upperBoxStyle.redBorderBox}>
                    <Box sx={upperBoxStyle.innerWhiteBox}/>
                </Box>
                <Box sx={upperBoxStyle.blackBannerBox}>
                    <Typography sx={upperBoxStyle.bannerText} variant={'h3'}>Services</Typography>
                </Box>
            </Box>

            <Grid container alignItems="center" justifyContent="center" width={"100%"} maxWidth={'1600px'} marginX={'auto'}>
                {data.map((Item, index) =>(<Grid key={index} item xs={12} sm={4} md={2.4} height={'100%'} alignItems={'stretch'}>
                    <Box sx={bottomBoxStyle.securityBoxMain}>
                        <Box sx={bottomBoxStyle.outerIconBox}>
                            <Box sx={bottomBoxStyle.whiteIconBox}>
                                <Box sx={bottomBoxStyle.blackIconBox}>
                                    {Item.icon}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={bottomBoxStyle.informationMainBox}>
                            <Typography variant={'h5'} sx={bottomBoxStyle.headingStyle}>{Item.heading}</Typography>
                            <Typography variant={'body2'} sx={bottomBoxStyle.textStyle}>{Item.text}</Typography>
                        </Box>
                    </Box>
                </Grid>))}
            </Grid>
        </Stack>
    </Box>)
}

export default servicesCard;