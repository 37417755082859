import bg from '../assets/imgs/about_bg.jpeg';

export const styleLeftGrid = {
    leftBlackBoxDesign: {
        clipPath: 'polygon(0 0, 100% 0%, 70% 100%, 0% 100%)',
        height: '100%',
        width: '75%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    leftRedBoxDesign: {
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0%, 75% 100%, 0% 100%)',
        height: '85%',
        width: '77.5%',
        bottom: '92%',
        backgroundColor: 'var(--red-theme-color)',
    },

    leftImageBox: {
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0%, 74% 100%, 0% 100%)',
        height: '80%',
        width: '69%',
        bottom: '174.5%',
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
};

export const styleRightGrid = {
    designUpperMainBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'end',
        height: '15%',
        width: '100%',
        overflow: 'hidden',
        py: '2rem',
    },

    rightUpperDesignBox: {
        position: 'relative',
        borderTop: '7px solid var(--red-theme-color)',
        borderLeft: '7px solid var(--red-theme-color)',
        transform: 'skewX(25deg)',
        height: '30%',
        width: '40%',
        right: '-35%',
    },

    rightBlackTextBox: {
        position: 'relative',
        backgroundColor: 'var(--grey-theme-color)',
        transform: 'skewX(25deg)',
        height: '50%',
        width: '75%',
        right: '-15%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    titleBanner: {
        fontSize: {md: '30px', lg: '35px', xl: '40px'},
        fontWeight: 'bold',
        color: '#FFF',
        transform: 'skewX(-25deg)',
    },

    infoText: {
        fontSize: {},
        width: {md: '425px', lg: '575px', xl: '750px'},
        marginLeft: 'auto',
        marginRight: '1rem',
    },

    bottomDesignMainBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        height: '12.5%',
        width: '100px',
        borderBottomLeftRadius: '50%',
        borderTopLeftRadius: '50%',
        marginLeft: 'auto',
        paddingRight: '1rem',
        backgroundColor: 'var(--red-theme-color)',
    },


    bottomRightIconBox: {
        height: '100px',
        width: '100px',
        backgroundColor: 'var(--grey-theme-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        flexGrow: 0,
    },

    bottomRightIcon: {
        height: '75px',
        width: '75px',
        color: '#FFF',
    }
};


export const smallAboutStyles = {
    topBannerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        clipPath: 'polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%)',
        paddingY: '0.5rem',
        backgroundColor: 'var(--grey-theme-color)',
        width: '100vw',
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    leftTriangle: {
        clipPath: 'polygon(100% 0, 0 0, 0 100%)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightTriangle: {
        clipPath: 'polygon(100% 100%, 100% 0, 0 0)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    leftBottomTriangle: {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightBottomTriangle: {
        clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    infoText: {
        width: '90%',
        borderLeft: '5px solid var(--red-theme-color)',
        borderRight: '5px solid var(--red-theme-color)',
        marginX: 'auto',
        padding: '0.5rem',
        fontSize: {sm: '22px'},
    },
}