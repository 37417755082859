import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {smSolutionStyles} from "../../stylesheets/solutionStyles";
import s1 from '../../assets/imgs/sl1.svg';
import s2 from '../../assets/imgs/sl2.svg';
import s3 from '../../assets/imgs/sl3.svg';

const SMSolutions = () => {
    const data = [
        {
            heading: 'Integrated Security System',
            icon: <img style={{height: '80%', width: '80%'}} src={s1} alt={'sol1'}/>,
            text: 'An integrated security system solution combines various security components like video surveillance, ' +
                'access control, alarm systems, and monitoring into a unified, cohesive system. This solution offers ' +
                'comprehensive coverage, seamless integration, and centralized control for enhanced security management ' +
                'across diverse environments, such as residential complexes, commercial spaces, or large-scale facilities.',
        },


        {
            heading: 'Risk Assessment and Customized Security Plan',
            icon: <img style={{height: '80%', width: '80%'}} src={s2} alt={'sol2'}/>,
            text: 'Conducting a thorough risk assessment allows for the identification of potential vulnerabilities and ' +
                'security gaps within an organization or property. Based on the assessment, a customized security plan ' +
                'is developed, tailoring security measures to address specific risks and concerns. This solution includes ' +
                'strategic recommendations for deploying manpower, implementing security technologies, establishing ' +
                'access controls, and emergency response protocols.',
        },

        {
            heading: '24/7 Remote Monitoring and Response Services',
            icon: <img style={{height: '80%', width: '80%'}} src={s3} alt={'sol3'}/>,
            text: 'Implementing round-the-clock remote monitoring and response services utilizes advanced technology to ' +
                'monitor security systems and premises remotely. Trained professionals oversee live surveillance feeds, ' +
                'analyze threats, and respond promptly to security incidents or alarms. This solution offers continuous ' +
                'vigilance, rapid response capabilities, and the ability to intervene in real-time, ensuring immediate ' +
                'action in the event of suspicious activities, breaches, or emergencies, providing peace of mind to ' +
                'clients about their property safety.',
        },
    ]

    return(<Box id={'solutions'}>
        <Stack>
            <Box sx={smSolutionStyles.outerRedBox}>
                <Box sx={smSolutionStyles.innerRedBox}>
                    <Box sx={smSolutionStyles.innerBlackBox}>
                        <Typography sx={smSolutionStyles.bannerTitle}>Solutions</Typography>
                    </Box>
                </Box>
            </Box>

            <Grid container rowGap={'1rem'}>
                {data.map((Object)=>(
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                <Box sx={smSolutionStyles.blackBackgroundDesign}/>
                                <Box sx={smSolutionStyles.redIconBox}>
                                    {Object.icon}
                                </Box>
                                <Box sx={smSolutionStyles.blackBackgroundDesign}/>
                            </Stack>
                        </Box>
                        <Stack sx={smSolutionStyles.bottomInfoBox}>
                            <Typography sx={smSolutionStyles.headingText}>{Object.heading}</Typography>
                            <Typography sx={smSolutionStyles.infoText}>{Object.text}</Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            <Box sx={smSolutionStyles.bottomBox}/>
        </Stack>
    </Box>)
}

export default SMSolutions;