import bg from '../assets/imgs/mission_bg.jpeg';

export const mainMissionOuterBox = {
    height: '100dvh',
}

export const leftGridStyles = {
    leftMainBox: {
        height: '15%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        overflow: 'hidden',
        py: '2rem',
    },

    leftUpperDesignBox: {
        position: 'relative',
        height: '40%',
        width: '40%',
        left: '-5%',
        transform: 'skewX(30deg)',
        border: '5px solid var(--red-theme-color)',
        py: '1rem',
    },

    leftBottomTitleBox: {
        position: 'relative',
        minHeight: '60%',
        width: '80%',
        bottom: -30,
        left: '-38%',
        transform: 'skewX(30deg)',
        backgroundColor: 'var(--grey-theme-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    titleBanner: {
        fontSize: {md: '30px', lg: '35px', xl: '40px'},
        fontWeight: 'bold',
        color: '#FFF',
        transform: 'skewX(-30deg)',
    },

    infoText: {
        fontSize: {md: '16px', lg: '18px', xl: '22px'},
        width: {md: '425px', lg: '575px', xl: '750px'},
        marginRight: 'auto',
        marginLeft: '1rem',
    },

    leftBottomMainDesignBox: {
        clipPath: 'polygon(0 0, 0% 100%, 100% 100%)',
        height: '30%',
        backgroundColor: 'white',
        width: '30%',
    },

    leftBottomBlackDesign: {
        position: 'relative',
        clipPath: 'polygon(0 0, 0% 100%, 100% 100%)',
        height: '80%',
        width: '80%',
        backgroundColor: 'var(--grey-theme-color)',
        bottom: "80%",
        left: 0,
    },

    leftBottomRedDesign: {
        position: 'relative',
        clipPath: 'polygon(0 20%, 0 0, 100% 100%, 80% 100%)',
        height: '100%',
        width: '100%',
        backgroundColor: 'var(--red-theme-color)',
        top: 0,
        left: 0,
    },

    leftBottomAccentDesign: {
        position: '',
        clipPath: 'polygon(0 20%, 0 0, 100% 100%, 80% 100%)',
        height: '100%',
        width: '100%',
        backgroundColor: 'var(--red-accent-color)',
        top: 0,
        left: 0,
    },

};

export const rightGridStyles = {
    rightBlackBackground: {
        position: 'relative',
        right: '-60%',
        height: '100%',
        width: '40%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    rightBorderBoxDesign: {
        position: 'relative',
        right: "-30%",
        bottom: '40%',
        width: '90%',
        height: '25%',
        transform: 'skewX(-30deg)',
        border: '10px solid var(--red-theme-color)',
    },

    rightImageBox: {
        position: 'relative',
        height: '75%',
        width: '58%',
        right: '-38%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bottom: '120%',
    },
};

export const smallMissionStyles= {
    topBannerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: '0.5rem',
        backgroundColor: 'var(--grey-theme-color)',
        width: '100vw',
        borderBottomLeftRadius: '100%',
        borderBottomRightRadius: '100%',
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    leftCircle: {
        borderTopRightRadius: '100%',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightCircle: {
        borderTopLeftRadius: '100%',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    infoText: {
        width: '90%',
        borderLeft: '5px solid var(--red-theme-color)',
        borderRight: '5px solid var(--red-theme-color)',
        marginX: 'auto',
        padding: '0.5rem',
        fontSize: {sm: '22px'},
    },

    leftBottomCircle: {
        borderBottomRightRadius: '100%',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },

    rightBottomCircle: {
        borderBottomLeftRadius: '100%',
        paddingY: {xs: '1rem', sm: '1.5rem'},
        backgroundColor: 'var(--red-theme-color)',
        width: '10%',
        height: '1rem',
    },
}