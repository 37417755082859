
export const mainBoxStyles = {
    paddingY: '1rem',
    backgroundColor: 'var(--grey-theme-color)',
    color: '#FFF',
}

export const titleStyles = {
    display: 'flex',
    width : '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: "var(--red-theme-color)",
    fontWeight: 'bold',
    marginBottom: '1rem',
    fontSize: {xs: '25px', sm: '30px', md: '30px', lg: '33px', xl: '35px'},
}

export const iconStylesLeft = {
    color: "var(--red-theme-color)",
    height: '35px',
    width: '35px',
}

export const gridStyles = {
    display: 'flex',
    flexDirection : 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: '1rem',
    rowGap: '1rem',
}

export const logoStyle = {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
}

export const logoTitleStyle = {
    fontSize: {xs: '25px', sm: '25px', md: '25px', lg: '25px', xl: '30px'},
    color: 'var(--red-theme-color)',
    fontWeight: 'bold',
    textAlign: 'center',
}

export const tabText = {
    fontSize: {xs: '15px', sm: '18px', md: '18px', lg: '19px', xl: '20px'},
}

export const arrowIconStyle = {
    color: 'var(--red-theme-color)',
    height: {xs: '25px', sm: '30px', md: '27px', lg: '32px', xl: '37px'},
    width: {xs: '25px', sm: '30px', md: '27px', lg: '32px', xl: '37px'},
}

export const tabContactText = {
    fontSize: {xs: '15px', sm: '18px', md: '18px', lg: '19px', xl: '20px'},
    wordBreak: 'break-word',
    transition: '1s',
}