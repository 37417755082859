import {Box, Grid, Stack, Typography} from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import {styles} from "../../stylesheets/headerStyles";
import {rightBoxStyles} from "../../stylesheets/headerStyles";
import logo from "../../assets/imgs/so_logo.jpeg";

const Header = () => {

    return(<Box id="home" height="100%">
        <Box sx={styles.mainOuterContainer}>
            <Grid container height="100%">
                <Grid item xs={12} md={6} height="100%" paddingY="4rem" sx={{zIndex: 10}}>
                    <Stack sx={styles.mainStack}>
                        <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                            <img style={styles.logo} src={logo} alt={'logo'}/>
                            <Typography variant={"h5"} sx={styles.webText}>Syed Saqlain Security Services LLC</Typography>
                        </Stack>

                        <Stack>
                            <Typography variant={"h1"} sx={styles.headerBannerText}>Your Security</Typography>
                            <Typography sx={styles.headerBannerBlackText} variant={"h1"}>Our Responsibility</Typography>
                            <Box sx={styles.horizontalBox}/>
                        </Stack>

                        <Stack direction={'row'} sx={styles.stackCentered}>
                            <LanguageIcon sx={styles.iconSizing}></LanguageIcon>
                            <Typography sx={styles.webText}>www.syedsaqlainsecurityservices.com</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} width="100%" height="100%" sx={{position: {xs: 'absolute', md: 'initial'}}}>
                    <Box width="100%" height="100%">
                        <Box sx={rightBoxStyles.rightTopLeftBox}/>
                        <Box sx={rightBoxStyles.rightImageBox}/>
                        <Box sx={rightBoxStyles.rightImageRibbon}/>
                        <Box sx={rightBoxStyles.rightCornerMainBox}>
                            <Box sx={rightBoxStyles.rightTopCornerMostInnerStyle}/>
                            <Box sx={rightBoxStyles.rightTopCornerStyle}>
                                <Box sx={rightBoxStyles.rightTopCornerInnerStyle}/>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>)
}

export default Header;