import React from "react";
import {createRoot} from "react-dom/client";
import './index.css';
import HomePage from "./client/LargeScreenView/HomePage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CareersPage from "./client/LargeScreenView/CareersPage";

const Index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/careers" element={<CareersPage />} />
            </Routes>
        </BrowserRouter>
    )
}

createRoot(document.getElementById('root')).render(<Index />);
