import React, {useState} from 'react';
import {Box, Stack, Typography, Collapse} from "@mui/material";
import {smServicesStyles} from "../../stylesheets/servicesStyles";
import s1 from '../../assets/imgs/s1.svg';
import s2 from '../../assets/imgs/s2.svg';
import s3 from '../../assets/imgs/s3.svg';
import s4 from '../../assets/imgs/s4.svg';
import s5 from '../../assets/imgs/s5.svg';

const SMServices = () => {
    const [selected, setSelected] = useState(0);
    const data = [
        {
            icon: <img style={smServicesStyles.iconStyle} src={s1} alt={'service1'}/>,
            title: 'Manned Guarding Services',
            details: 'Syed Security Services provide a comprehensive suite of Manned Guarding Services. Our highly ' +
                'trained and skilled security personnel are equipped with cutting-edge tools and extensive expertise ' +
                'in surveillance, access control, and risk assessment. We pride ourselves on delivering proactive ' +
                'security measures, ensuring a visible and vigilant presence at all times. Our tailored security ' +
                'solutions cater to a diverse range of industries, including corporate establishments, residential ' +
                'complexes, retail spaces, and special events. At Syed Security Services, we prioritize client ' +
                'satisfaction, offering not just protection but also unparalleled customer service, conflict ' +
                'resolution, and personalized security strategies. Choose Syed Security Services for a reliable ' +
                'partner dedicated to fortifying your safety and securing your assets.'
        },

        {
            icon: <img style={smServicesStyles.iconStyle} src={s4} alt={'service2'}/>,
            title: 'Event Security Personnel',
            details: 'At Syed Security Services, our team of specialized event security professionals is meticulously ' +
                'trained to handle various aspects of event security management. From crowd control and access ' +
                'management to emergency response and risk assessment, our personnel are equipped with the ' +
                'expertise to maintain a safe and controlled environment. Their presence serves as a reassuring ' +
                'force, offering attendees a sense of safety and allowing event organizers to focus on delivering ' +
                'a memorable experience. With a keen eye on potential risks and a proactive approach, our event ' +
                'security personnel are committed to upholding the highest standards of safety, ensuring the success ' +
                'and seamless execution of every event they protect.'
        },

        {
            icon: <img style={smServicesStyles.iconStyle} src={s3} alt={'service3'}/>,
            title: 'Mobile Patrol Security',
            details: 'Mobile Patrol Security services offered by Syed Security Solutions provide a dynamic ' +
                'and responsive approach to safeguarding properties and assets. Our mobile patrol units are ' +
                'equipped with trained security professionals who conduct regular, thorough inspections of ' +
                'designated areas. These mobile patrols serve as a visible deterrent, deterring criminal ' +
                'activity and ensuring a proactive response to any security concerns. At Syed Security ' +
                'Solutions, we prioritize rapid intervention, timely reporting, and continuous monitoring ' +
                'to mitigate risks effectively. Our patrol officers are adept at covering diverse locations, ' +
                'including residential areas, commercial spaces, industrial sites, and construction zones. ' +
                'Through a combination of vigilance, advanced technology, and strategic patrolling routes, ' +
                'we offer our clients peace of mind knowing that their properties are under constant watch ' +
                'by our mobile security patrols.'
        },

        {
            icon: <img style={smServicesStyles.iconStyle} src={s5} alt={'service4'}/>,
            title: 'Access Control Management',
            details: 'Access Control Management stands at the forefront of ensuring secure and regulated entry to ' +
                'premises, bolstering safety measures for businesses and organizations. At Syed Security Solutions, ' +
                'our Access Control Management services offer a comprehensive approach to controlling and monitoring ' +
                'access points. We employ cutting-edge technology combined with expert strategies to restrict ' +
                'unauthorized entry while allowing seamless access for authorized personnel. Our tailored solutions' +
                ' encompass a range of access control systems, including keycards, biometric scanners, and advanced ' +
                'software, providing clients with flexible and customizable security protocols. By implementing ' +
                'stringent access control measures, we not only fortify the physical security of facilities but also ' +
                'enhance data protection and confidentiality. Syed Security Solutions takes pride in delivering ' +
                'reliable and scalable access control solutions that adapt to the evolving security needs of our ' +
                'clients, ensuring a secure environment and peace of mind.'
        },

        {
            icon: <img style={smServicesStyles.iconStyle} src={s2} alt={'service5'}/>,
            title: 'Executive Protection Services',
            details: 'At Syed Security Services, we specialize in providing discreet and highly effective Executive ' +
                'Protection Services tailored to safeguard high-profile individuals, corporate leaders, and VIPs. ' +
                'Our team comprises extensively trained security professionals adept at assessing risks and ' +
                'implementing personalized security strategies. We prioritize the safety and privacy of our ' +
                'clients through meticulous planning, vigilant surveillance, and seamless coordination. Syed ' +
                'Security Services offers a range of executive protection measures, including close protection, ' +
                'secure transportation, threat assessment, and 24/7 monitoring. With a commitment to professionalism ' +
                'and confidentiality, we ensure a secure environment, allowing our clients to carry out their ' +
                'responsibilities with peace of mind and without disruptions. Choose Syed Security Services for ' +
                'expertly crafted and personalized executive protection solutions that prioritize your safety ' +
                'above all else.'
        },
    ]

    const handleInfoClick = (index) => {
        setSelected(index);
    }

    return(<Box id={'services'}>
        <Box sx={smServicesStyles.mainOuterBox}>
            <Box sx={smServicesStyles.topBannerBox}>
                <Typography sx={smServicesStyles.bannerTitle}>Services</Typography>
            </Box>
            <Stack rowGap={2} paddingX={'0.5rem'} alignItems={'center'} justifyContent={'center'}>
                {data.map((Item, num) =>(<Box sx={smServicesStyles.servicesMainBox}>
                        <Box sx={smServicesStyles.servicesUpperBox} onClick={() => handleInfoClick(num)}>
                        <Typography sx={smServicesStyles.numberText}>{num+1}</Typography>
                        <Typography sx={smServicesStyles.titleText}>{Item.title}</Typography>
                        <Box sx={smServicesStyles.iconBox}>
                            {Item.icon}
                        </Box>
                    </Box>
                    <Collapse in={selected === num} timeout="auto" unmountOnExit>
                        <Typography sx={smServicesStyles.infoText}>
                            {Item.details}
                        </Typography>
                    </Collapse>
                </Box>))}
            </Stack>
        </Box>
    </Box>)
}

export default SMServices;