import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {smallVisionStyles} from "../../stylesheets/visionStyles";

const SMVision = () => {
    return(<Box>
        <Box sx={smallVisionStyles.topBannerBox}>
            <Typography sx={smallVisionStyles.bannerTitle}>Our Vision</Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallVisionStyles.leftBox}/>
            <Box sx={smallVisionStyles.rightBox}/>
        </Stack>
        <Typography sx={smallVisionStyles.infoText}>
            Our goal is to become the best provider of great staff solutions. We want to set a high
            standard in the industry by being really professional, dependable, and coming up with new
            ideas. We want to reach more places by using the latest technologies and our amazing skills
            to make places safer. We want our clients to trust us completely with their security needs.
            We are committed to doing an excellent job and always getting better. We want people to see
            us as the reliable partner who keeps communities and valuable things safe.
        </Typography>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallVisionStyles.leftBottomBox}/>
            <Box sx={smallVisionStyles.rightBottomBox}/>
        </Stack>
    </Box>)
}

export default SMVision;