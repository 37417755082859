import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {mainMissionOuterBox, leftGridStyles, rightGridStyles} from "../../stylesheets/missionStyles";

const Mission = () =>{
    return(<Box height='100%'>
        <Box sx={mainMissionOuterBox}>
            <Grid container height="100%">
                <Grid item xs={12} md={6} height="100%">
                    <Stack height="100%" width="100%" justifyContent="space-between">
                        <Box sx={leftGridStyles.leftMainBox}>
                            <Box sx={leftGridStyles.leftUpperDesignBox}/>
                            <Box sx={leftGridStyles.leftBottomTitleBox}>
                                <Typography variant={'h3'} sx={leftGridStyles.titleBanner}>Our Mission</Typography>
                            </Box>
                        </Box>

                        <Typography sx={leftGridStyles.infoText} variant={'h6'}>
                            Our mission is to provide exceptional manpower solutions tailored to our clients' needs,
                            ensuring a safe and secure environment through our highly trained and professional security
                            personnel. We aim to be the preferred partner for our clients by delivering reliable, skilled,
                            and efficient manpower services. We strive to uphold the highest standards of integrity, training,
                            and responsiveness, committed to safeguarding lives and assets while contributing to our clients'
                            peace of mind.
                        </Typography>

                        <Box sx={leftGridStyles.leftBottomMainDesignBox}>
                            <Box sx={leftGridStyles.leftBottomAccentDesign}/>
                            <Box sx={leftGridStyles.leftBottomBlackDesign}>
                                <Box sx={leftGridStyles.leftBottomRedDesign}/>
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item x={12} md={6} height="100%" width="100%">
                    <Box height="100%" width="100%" overflow="hidden">
                        <Box sx={rightGridStyles.rightBlackBackground}/>
                        <Box sx={rightGridStyles.rightBorderBoxDesign}/>
                        <Box sx={rightGridStyles.rightImageBox}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>)
}

export default Mission;