

export const upperStyles = {
    mainBoxStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
        width: '100%',
        overflow: 'visible',
        marginBottom: '3rem',
    },

    redBorderBox: {
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
        height: '75%',
        backgroundColor: 'var(--red-theme-color)',
        width: '35%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
    },

    innerWhiteBox: {
        clipPath: 'polygon(0 0, 100% 0, 95.3% 100%, 4.8% 100%)',
        height: '91%',
        backgroundColor: '#FFF',
        width: '97%',
    },

    blackBannerBox: {
        position: 'relative',
        bottom: '20%',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    bannerText: {
        fontSize: {md: '35px', lg: '40px', xl: '45px'},
        fontWeight: 'bold',
        color: '#FFF',
    }
};

export const redBoxesStyles = {
    mainUpperMiddleBox: {
        position: 'absolute',
        display: 'flex',
        top: '12.5%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '160px',
        width: '100%',
        zIndex: -1,
    },

    blackBackgroundBox: {
        backgroundColor: 'var(--grey-theme-color)',
        width: '100%',
        height: '190px',
        zIndex: -1,
    },

    mainGridContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        height: {md: '800px', lg: '700px', xs: '600px'},
        width: '100%',
        backgroundColor: 'transparent',
        position: 'relative',
    },

    redBoxesMainBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--red-theme-color)',
        width: '100%',
        height: '45%',
    }
};

export const bottomStyles = {
    mainBottomBox: {
        height: '50px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
        bottom: '0%'
    },

    blackBoxDesign: {
        clipPath: 'polygon(1% 0%, 99% 0, 100% 100%, 0% 100%)',
        backgroundColor: 'var(--grey-theme-color)',
        height: '50%',
        width: '85%',
    }
};

export const smSolutionStyles = {
    outerRedBox: {
        width: '100vw',
        backgroundColor: 'var(--red-theme-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0.75rem',
    },

    innerRedBox: {
        width: '100%',
        backgroundColor: 'var(--red-accent-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: '0.5rem',
        marginY: '0.5rem',
    },

    innerBlackBox: {
        width: '100%',
        backgroundColor: 'var(--grey-theme-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: '0.5rem',
        marginY: '0.5rem',
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    blackBackgroundDesign: {
        width: '25%',
        height: '25%',
        paddingY: '3rem',
        backgroundColor: 'var(--grey-theme-color)',
    },

    redIconBox: {
        width: '70%',
        height: '100%',
        backgroundColor: 'var(--red-theme-color)',
        maxWidth: {xs: '300px', sm: '100%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    bottomInfoBox: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.75rem',
        maxWidth: {xs: '500px'},
        marginX: 'auto',
    },

    headingText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: {xs: '25px', sm: '30px'},
        marginBottom: '0.5rem',
    },

    infoText: {
        fontSize: {xs: '16px', sm: '20px'},
    },

    bottomBox: {
        height: '2rem',
        backgroundColor: 'var(--grey-theme-color)',
        clipPath: 'polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%)',
        width: '100vw',
        marginTop: '0.75rem',
    },
};