import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {smallAboutStyles} from "../../stylesheets/aboutStyles";

const SMAbout = () => {
    return(<Box id={'about'}>
        <Box sx={smallAboutStyles.topBannerBox}>
            <Typography sx={smallAboutStyles.bannerTitle}>About Us</Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallAboutStyles.leftTriangle}/>
            <Box sx={smallAboutStyles.rightTriangle}/>
        </Stack>
        <Typography sx={smallAboutStyles.infoText}>
            Syed Saqlain Security Services LLC is a reputable security services company dedicated to
            providing comprehensive security solutions tailored to meet the specific needs of its clients.
            With a commitment to excellence and safety, the company offers a wide range of security services
            aimed at safeguarding individuals, properties, and assets.
        </Typography>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100vw'}>
            <Box sx={smallAboutStyles.leftBottomTriangle}/>
            <Box sx={smallAboutStyles.rightBottomTriangle}/>
        </Stack>
    </Box>)
}

export default SMAbout