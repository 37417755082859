import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {styleLeftGrid, styleRightGrid} from "../../stylesheets/aboutStyles";
import logo from '../../assets/imgs/about_icon.svg';

const About = () => {
    return(<Box id="about" height="100%">
        <Box height="100dvh">
            <Grid container height="100%">
                <Grid item height="100%" xs={12} md={6}>
                    <Box sx={styleLeftGrid.leftBlackBoxDesign}/>
                    <Box sx={styleLeftGrid.leftRedBoxDesign}/>
                    <Box sx={styleLeftGrid.leftImageBox}/>
                </Grid>
                <Grid item xs={12} md={6} justifyContent="space-between" display="flex" flexDirection="column" paddingBottom="2rem">
                    <Box sx={styleRightGrid.designUpperMainBox}>
                        <Box sx={styleRightGrid.rightUpperDesignBox}/>
                        <Box sx={styleRightGrid.rightBlackTextBox}>
                            <Typography variant={'h3'} sx={styleRightGrid.titleBanner}>About Company</Typography>
                        </Box>
                    </Box>

                    <Typography sx={styleRightGrid.infoText} variant={'h5'}>
                        Syed Saqlain Security Services LLC is a reputable security services company dedicated to
                        providing comprehensive security solutions tailored to meet the specific needs of its clients.
                        With a commitment to excellence and safety, the company offers a wide range of security services
                        aimed at safeguarding individuals, properties, and assets.
                    </Typography>
                    <Box sx={styleRightGrid.bottomDesignMainBox}>
                        <Box sx={styleRightGrid.bottomRightIconBox}>
                            <img style={styleRightGrid.bottomRightIcon} src={logo} alt={'logo'}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>)
}

export default About;