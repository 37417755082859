
export const appbarStyle = {
    backgroundColor : 'var(--red-theme-color)',
}

export const nameStyle = {
    fontSize: {xs: '15px', sm: '20px', md: '18px', lg: '25px'},
    textAlign: 'center',
    color: 'var(--grey-theme-color)',
    fontWeight: 'bold',
}

export const toolbarStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '1rem',
}

export const logoStyle = {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
}

export const buttonStyles = {
    padding: '0.5rem 1rem',
    marginRight: {md: '0.25rem', lg: '0.5rem'},
    marginLeft: {md: '0.25rem', lg: '0.5rem'},
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: 'var(--grey-theme-color)',
    border: '1px solid var(--grey-theme-color)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    transition: '0.7s',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover' : {
        backgroundColor: 'var(--red-theme-color)',
        borderColor: 'var(--grey-theme-color)',
    },
}

export const drawerStyles = {
    drawerSX: {
        width: '90%',
        marginY: '10px',
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'var(--grey-theme-color)',
    },

    listStyle: {
        textAlign: 'start',
        fontWeight: 'bold',
        color : 'var(--red-theme-color)',
        width: '100%',
    },

    tabIconStyle: {
        color: 'var(--red-theme-color)',
        marginRight: '1rem',
    }
}