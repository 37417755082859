import Img1 from "./assets/imgs/real/pic1.jpeg";
import Img2 from "./assets/imgs/real/pic2.jpeg";
import Img3 from "./assets/imgs/real/pic3.jpeg";
import Img4 from "./assets/imgs/real/pic4.jpeg";
import Img5 from "./assets/imgs/real/pic5.jpeg";


export const realImgs = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5
]
export const imgStyle = {
    backgroundColor:"",
    height : "200px",
    padding  : "1%",
    marginTop  : "1%"
}
export const mainContainer = {
    flexWrap: "wrap",
    display:"flex" , 
    justifyContent:"center"
}