
export const upperBoxStyle = {
    mainUpperBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '150px',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '3rem',
    },

    redBorderBox: {
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
        height: '75%',
        backgroundColor: 'var(--red-theme-color)',
        width: '35%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
    },

    innerWhiteBox: {
        clipPath: 'polygon(0 0, 100% 0, 95.3% 100%, 4.8% 100%)',
        height: '91%',
        backgroundColor: '#FFF',
        width: '97%',
    },

    blackBannerBox: {
        position: 'relative',
        bottom: '20%',
        clipPath: 'polygon(5% 0, 95% 0, 100% 100%, 0% 100%)',
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    bannerText: {
        fontSize: {md: '35px', lg: '40px', xl: '45px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

};

export const bottomBoxStyle = {
    securityBoxMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100%',
    },

    outerIconBox: {
        height: {md: '175px', lg: '200px'},
        width: {md: '175px', lg: '200px'},
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: 'var(--red-theme-color)',
    },

    whiteIconBox: {
        height: {md: '150px', lg: '175px'},
        width: {md: '150px', lg: '175px'},
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#FFF',
    },

    blackIconBox: {
        height: {md: '135px',lg: '160px'},
        width: {md: '135px',lg: '160px'},
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    iconStyle: {
        height: '90%',
        width: '90%',
    },

    informationMainBox: {
        mt: '1rem',
        px: '0.5rem',
        backgroundColor: 'var(--grey-theme-color)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: {md: '450px', lg: '340px', xl: '260px'}
    },

    textStyle: {
        mt: '3rem',
        color: '#FFF',
    },

    headingStyle: {
        mt: '1rem',
        color: 'var(--red-theme-color)',
    }
};

export const smCardsStyle = {
    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    outerRedCircle: {
        width: {xs: '120px', sm: '140px'},
        height: {xs: '120px', sm: '140px'},
        borderRadius: '50%',
        backgroundColor: 'var(--red-theme-color)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginX: 'auto',
    },

    innerWhiteCircle: {
        width: {xs: '105px', sm: '125px'},
        height: {xs: '105px', sm: '125px'},
        borderRadius: '50%',
        backgroundColor: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    innerBlackBox: {
        width: {xs: '90px', sm: '110px'},
        height: {xs: '90px', sm: '110px'},
        borderRadius: '50%',
        backgroundColor: 'var(--grey-theme-color)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    bannerBox: {
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
        backgroundColor: 'var(--red-accent-color)',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0.75rem',
    },

    innerRedBox: {
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
        backgroundColor: 'var(--grey-theme-color)',
        width: '98%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginY: '1%',
    },

    iconStyle: {
        height: '75%',
        width: '75%',
    },

    bottomInformationBox: {
        backgroundColor: 'var(--grey-theme-color)',
        padding: '0.5rem',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },

    headingText: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'var(--red-theme-color)',
        fontSize: {xs: '20px', sm: '26px'},
    },

    infoText: {
        maxWidth: {xs: '450px', sm: '300px'},
        color: '#FFF',
        fontSize: {xs: '15px', sm: '18px'},
    },

    bottomBox: {
        height: '2rem',
        backgroundColor: 'var(--grey-theme-color)',
        clipPath: 'polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%)',
        width: '100vw',
        marginTop: '0.75rem',
    },
}