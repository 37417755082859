import React from 'react'
import {Box, Stack, Typography} from "@mui/material";
import {smTeamStyles} from "../../stylesheets/teamStyles";
import {realImgs,imgStyle,mainContainer} from "../../allImages";
const SMTeam = () => {
    return(<Box id={'team'}>
        <Stack>
            <Box sx={smTeamStyles.outerRedBox}>
                <Box sx={smTeamStyles.innerRedBox}>
                    <Box sx={smTeamStyles.innerBlackBox}>
                        <Typography sx={smTeamStyles.bannerTitle}>Team</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={mainContainer}>{realImgs.map((item , key)=>{
                return <img style = {imgStyle} src={item} alt="realImgs" />
            })}</Box>
            <Typography sx={smTeamStyles.infoText}>
                With a strong emphasis on professionalism, integrity, and teamwork, our security personnel work
                cohesively to provide effective security solutions tailored to meet the unique needs of our clients.
                Whether it's manned guarding, event security, access control, or specialized protection services,
                our team is dedicated to delivering high-quality, reliable, and proactive security measures.
            </Typography>
            <Box sx={smTeamStyles.mainBottomBox}>
                <Box sx={smTeamStyles.bottomRedBox}>
                    <Box sx={smTeamStyles.imageBox}></Box>
                </Box>
                <Box sx={smTeamStyles.bottomBlackDesign}/>
            </Box>
        </Stack>
    </Box>)
}

export default SMTeam;