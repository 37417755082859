import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {leftGridStyles, rightGridStyles} from "../../stylesheets/visionStyles";

const Vision = () => {
    return(<Box height="100%">
        <Box height="100dvh">
            <Grid container height="100%">
                <Grid item xs={12} md={6} height="100%">
                    <Box sx={leftGridStyles.blackBackgroundBox}/>
                    <Box sx={leftGridStyles.leftBoxImage}/>
                    <Box sx={leftGridStyles.leftRedBorderBox}/>
                </Grid>
                <Grid item xs={12} md={6} height="100%" paddingY="2rem">
                    <Stack height={'100%'}>
                        <Box sx={rightGridStyles.topMainDesignBox}>
                            <Box sx={rightGridStyles.rightBorderBoxDesign}/>
                            <Box sx={rightGridStyles.rightBannerBox}>
                                <Typography sx={rightGridStyles.bannerText} variant={'h3'}>Our Vision</Typography>
                            </Box>
                        </Box>
                        <Typography variant={'h6'} sx={rightGridStyles.infoText}>
                            Our goal is to become the best provider of great staff solutions. We want to set a high
                            standard in the industry by being really professional, dependable, and coming up with new
                            ideas. We want to reach more places by using the latest technologies and our amazing skills
                            to make places safer. We want our clients to trust us completely with their security needs.
                            We are committed to doing an excellent job and always getting better. We want people to see
                            us as the reliable partner who keeps communities and valuable things safe.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    </Box>)
}

export default Vision;