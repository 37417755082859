import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {smHeaderStyle as smallHeaderStyles, styles} from "../../stylesheets/headerStyles";
import LanguageIcon from "@mui/icons-material/Language";

const SMHeader = () => {
    return(<Box id={'home'} sx={smallHeaderStyles.mostOuterBox}>
        <Stack sx={smallHeaderStyles.mainStack}>
            <Stack direction={'row'} sx={smallHeaderStyles.innerStack}>
                <Box sx={smallHeaderStyles.logoBox}/>
                <Typography variant={"h5"} sx={smallHeaderStyles.webTextSM}>Syed Saqlain Security Services LLC</Typography>
            </Stack>

            <Stack sx={{height: '20%', alignItems: 'center', justifyContent: 'center', marginX: '0.5rem', textAlign: 'center', wordBreak: 'break-all'}}>
                <Typography variant={"h1"} sx={styles.headerBannerText}>Your Security</Typography>
                <Typography sx={styles.headerBannerBlackText} variant={"h1"}>Our Responsibility</Typography>
                <Box sx={styles.horizontalBox}/>
            </Stack>

            <Stack direction={'row'} sx={smallHeaderStyles.innerStack}>
                <LanguageIcon sx={styles.iconSizing}></LanguageIcon>
                <Typography sx={smallHeaderStyles.webTextSM}>www.syedsaqlainsecurityservices.com</Typography>
            </Stack>
        </Stack>
    </Box>)
}

export default SMHeader;