import bg from '../assets/imgs/services_bg.jpeg';

export const styleLeftBox = {
    mainServicesOuterBox: {
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },

    servicesMainBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: {md: '425px', lg: '525px', xl: '750px'},
        backgroundColor: 'var(--red-theme-color)',
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        cursor: 'pointer',
    },

    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: {md: '112px', lg: '115px', xl: '120px'},
        width: {md: '112px', lg: '115px', xl: '120px'},
        borderRadius: '25px',
        backgroundColor: 'var(--grey-theme-color)',
        color: '#FFFFFF'
    },

    iconStyle: {
        height: '75%',
        width: '75%',
    },

    titleText: {
        fontSize: {md: '18px', lg: '22px', xl: '30px'},
    },
};

export const styleRightBox = {
    rightDesignBoxParent: {
        position: 'relative',
        height: '20%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
    },

    rightDesignTop: {
        position: 'absolute',
        clipPath: 'polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%)',
        height: '75%',
        width: '60%',
        backgroundColor: 'transparent',
        bottom: '100%',
    },

    rightDesignTopInner: {
        position: 'relative',
        top: 0,
        clipPath: 'polygon(5% 0, 100% 0, 100% 10%, 0 10%)',
        height: '100%',
        width: '100%',
        backgroundColor: 'var(--red-theme-color)',
        zIndex: 1,
    },

    rightDesignTopInnerLeft: {
        position: 'relative',
        left: "0%",
        top: "-100%",
        clipPath: 'polygon(5% 0, 60% 0, 10% 100%, 0 100%)',
        height: '100%',
        width: '10%',
        backgroundColor: 'var(--red-theme-color)',
        zIndex: 2,
    },


    rightDesignBottom: {
        clipPath: 'polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%)',
        height: '30%',
        backgroundColor: 'var(--grey-theme-color)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: '0.5rem',
    },

    bannerText: {
        fontSize: {md: '30px', lg: '35px', xl: '40px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    infoBox: {
        color: '#FFFFFF',
        height: '75%',
        width: {md: '425px', lg: '575px', xl: '750px'},
    },

    headingText: {
        fontWeight: 'bold',
        fontSize: {md: '25px', lg: '33px', xl: '35px'},
    },

    infoText: {
        fontSize: {lg: '19px', xl: '22px'},
    },
}

export const smServicesStyles = {
    mainOuterBox: {
        height: '100%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        paddingBottom: {xs: '0.5rem', sm: '0.75rem'},
    },

    topBannerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        clipPath: 'polygon(40% 0, 60% 0, 100% 50%, 60% 100%, 40% 100%, 0% 50%)',
        paddingY: '0.5rem',
        backgroundColor: 'var(--grey-theme-color)',
        width: '100vw',
        marginBottom: {xs: '0.5rem', sm: '0.75rem'},
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },
    servicesMainBox: {
        width: {xs: '95%', sm: '95%'},
        backgroundColor: 'var(--red-theme-color)',
        borderRadius : '10px',
        transition: '0.75s',
        '&:hover' : {
            boxShadow: '0px 4px 8px rgba(192,24,34,0.5)',
        },
    },

    servicesUpperBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '0.5rem',
        cursor: 'pointer',
    },

    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: 'var(--grey-theme-color)',
        color: '#FFFFFF',
        height: {xs: '60px', sm: '75px'},
        width: {xs: '60px', sm: '75px'},
    },

    iconStyle: {
        height: '75%',
        width: '75%',
    },

    titleText: {
        fontSize: {xs: '14px', sm: '22px'},
        textAlign: 'center',
        fontWeight: 'bold',
    },

    numberText: {
        fontWeight: 'bold',
        color: 'rgba(0,0,0,0.5)',
        fontSize: {xs: '25px', sm: '35px'},
    },

    infoText: {
        margin: '0.75rem',
        padding: '0.75rem',
        border: '2px solid var(--grey-theme-color)',
        borderRadius: '10px',
        fontSize: {xs: '14px', sm: '20px'},
    }
};