import React from "react";
import {Box, Typography} from "@mui/material";
import {bottomStyles, middleStyles, upperBoxStyles} from "../../stylesheets/teamStyles";
import {realImgs,imgStyle,mainContainer} from "../../allImages";
// import Img5 from "../../assets/imgs/real/pic5.jpeg";

const Team = () => {
    return(<Box id="team" height={'100%'}>
        <Box>
            <Box sx={upperBoxStyles.mainUpperBox}>
                <Box sx={upperBoxStyles.redBorderBox}>
                    <Box sx={upperBoxStyles.innerWhiteBox}/>
                </Box>
                <Box sx={upperBoxStyles.blackBannerBox}>
                    <Typography variant={'h3'} sx={upperBoxStyles.titleText}>Team</Typography>
                </Box>
            </Box>
            <Box sx={mainContainer}>{realImgs.map((item , key)=>{
                return <img style = {imgStyle} src={item} alt="realImgs" />
            })}</Box>

            <Box sx={middleStyles.middleMainBox}>
                <Typography variant={'h5'} maxWidth={'1600px'} marginX={'auto'}>
                    With a strong emphasis on professionalism, integrity, and teamwork, our security personnel work
                    cohesively to provide effective security solutions tailored to meet the unique needs of our clients.
                    Whether it's manned guarding, event security, access control, or specialized protection services,
                    our team is dedicated to delivering high-quality, reliable, and proactive security measures.
                </Typography>
            </Box>

            <Box sx={bottomStyles.mainBottomBox}>
                <Box sx={bottomStyles.bottomRedBox}>
                    <Box sx={bottomStyles.imageBox}></Box>
                </Box>
                <Box sx={bottomStyles.bottomBlackDesign}/>
            </Box>
        </Box>
    </Box>)
}

export default Team;