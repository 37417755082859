import bg from '../assets/imgs/team_bg.jpeg';

export const upperBoxStyles = {
    mainUpperBox: {
        position: 'relative',
        height: '125px',
        paddingBottom: '2rem',
    },

    redBorderBox: {
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
        height: '75%',
        backgroundColor: 'var(--red-theme-color)',
        width: '50%',
        left: '25%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
    },

    innerWhiteBox: {
        clipPath: 'polygon(0 0, 100% 0, 95.3% 100%, 4.8% 100%)',
        height: '89%',
        backgroundColor: '#FFF',
        width: '97%',
    },

    blackBannerBox: {
        position: 'relative',
        bottom: '20%',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
        width: '25%',
        left: '37.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    titleText: {
        fontSize: {md: '35px', lg: '40px', xl: '45px'},
        fontWeight: 'bold',
        color: '#FFF',
    },
};

export const middleStyles = {
    middleMainBox: {
        marginY: {md: '5rem', lg: '3rem', xl: '2rem'},
        position: 'relative',
        height: '35%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        paddingX: '5rem',
        paddingBottom: '2rem',
    },
}

export const bottomStyles = {
    mainBottomBox: {
        position: 'relative',
        height: {md: '250px', lg: '300px', xl: '300px'},
    },

    bottomBlackDesign: {
        position: 'relative',
        top: '40%',
        zIndex: 1,
        width: '100%',
        height: '60%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    bottomRedBox: {
        position: 'absolute',
        height: '100%',
        width: '90%',
        left: '5%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        backgroundColor: 'var(--red-theme-color)',
        zIndex: 2,
        top: '-20%',
    },

    imageBox: {
        position: 'relative',
        height: '100%',
        width: '97%',
        top: '7%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}

export const smTeamStyles = {
    outerRedBox: {
        width: '100vw',
        clipPath: 'polygon(0 0, 100% 0, 90% 100%, 10% 100%)',
        backgroundColor: 'var(--red-theme-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0.75rem',
    },

    innerRedBox: {
        width: '100%',
        clipPath: 'polygon(1.5% 0, 98.5% 0, 90% 100%, 10% 100%)',
        backgroundColor: 'var(--red-accent-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: '0.5rem',
        marginY: '0.5rem',
    },

    innerBlackBox: {
        width: '100%',
        clipPath: 'polygon(3.5% 0, 96.5% 0, 90% 100%, 10% 100%)',
        backgroundColor: 'var(--grey-theme-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: '0.5rem',
        marginY: '0.5rem',
    },

    bannerTitle: {
        fontSize: {xs: '25px', sm: '35px'},
        fontWeight: 'bold',
        color: '#FFF',
    },

    infoText: {
        padding: '1rem',
        fontSize: {xs: '16px', sm: '20px'},
        maxWidth: '750px',
        marginX: 'auto',
    },

    mainBottomBox: {
        marginTop: '3rem',
        position: 'relative',
        height: {xs: '200px', sm: '250px'},
        width: '100vw',
    },

    bottomBlackDesign: {
        position: 'relative',
        top: '40%',
        zIndex: 1,
        width: '100%',
        height: '60%',
        backgroundColor: 'var(--grey-theme-color)',
    },

    bottomRedBox: {
        position: 'absolute',
        height: '100%',
        width: '90%',
        left: '5%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        backgroundColor: 'var(--red-theme-color)',
        zIndex: 2,
        top: '-20%',
    },

    imageBox: {
        position: 'relative',
        height: '100%',
        width: '97%',
        top: '7%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}